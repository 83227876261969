import { ReactElement } from 'react'
import { Trans, t } from '@lingui/macro'
import classnames from 'classnames'

import { VideoInfoFragment as Video } from '../../api/types'
import { WithProps } from '../../lib/add-state'
import { formatDuration } from '../../lib/format-duration'
import { Link } from '../../lib/components/link'
import { YouTubePlayer } from '../../lib/components/youtube-player'
import { Section } from '../../lib/components/section'
import { Play, Pause, InfoCircle } from '../../lib/components/icon'
import { useLocalisation } from '../../lib/i18n'
import { AutoTooltip } from '@discogs/amped/tooltip'

import { Props, State, Action } from '.'

import css from './styles.css'

type UIProps = WithProps<Props, State, Action>

export function VideosUI(props: UIProps): ReactElement | null {
    const { index, dispatch, error, videos, playing, editUrl } = props

    const count = videos.length
    const title = (
        <>
            <Trans>Videos</Trans> {count > 0 ? ` (${count})` : ''}
        </>
    )

    if (error) {
        return (
            <Section title={title} collapsible id='release-videos'>
                <Trans>An error occurred when loading the videos.</Trans>
            </Section>
        )
    }

    const header = (
        <>
            <h2 className={css.head}>{title}</h2>
            <InfoCircle className={css.icon} aria-describedby='video-info' />
            <AutoTooltip id='video-info'>
                <Trans>
                    Discogs is not responsible for the accuracy, legality, or content of external sites or for that of
                    subsequent links.
                </Trans>
            </AutoTooltip>
            {editUrl && (
                <Link href={editUrl} className={css.edit}>
                    <Trans>Edit</Trans>
                </Link>
            )}
        </>
    )

    return (
        <Section id='release-videos' header={header}>
            {videos.length > 0 && (
                <div className={css.player}>
                    <YouTubePlayer
                        videos={videos}
                        width='100%'
                        height={240}
                        playing={playing}
                        index={index ?? undefined}
                        onPlay={(): void => dispatch({ type: 'play' })}
                        onPause={(): void => dispatch({ type: 'pause' })}
                        onIndexChange={(index: number): void => dispatch({ type: 'index', index })}
                    />
                </div>
            )}
            {videos.length === 0 && editUrl && (
                <Link href={editUrl}>
                    <Trans>Add video</Trans>
                </Link>
            )}
            <ul className={css.videos}>
                {videos.map(
                    (v: Video, idx: number): ReactElement => (
                        <Item
                            video={v}
                            key={v.discogsId}
                            dispatch={dispatch}
                            index={idx}
                            active={index === idx}
                            playing={playing}
                        />
                    ),
                )}
            </ul>
        </Section>
    )
}

type ItemProps = {
    video: Video
    dispatch: UIProps['dispatch']
    index: number
    playing: boolean
    active: boolean
}

function Item(props: ItemProps): ReactElement {
    const { video, dispatch, index, active, playing } = props
    const { title, duration, youtubeId } = video

    const { i18n } = useLocalisation()

    const classname = classnames(css.video, active && playing && css.active)

    return (
        <li>
            <button type='button' className={classname} onClick={(): void => dispatch({ type: 'select', index })}>
                <Thumbnail youtubeId={youtubeId} />
                <Play aria-label={i18n._(t`Play`)} className={css.play} aria-hidden={playing} />
                <Pause aria-label={i18n._(t`Pause`)} className={css.pause} aria-hidden={!playing} />
                <div className={css.title}>{title}</div>
                <Duration value={duration} />
            </button>
        </li>
    )
}

type ThumbnailProps = {
    youtubeId: string
}

function Thumbnail(props: ThumbnailProps): ReactElement {
    const { youtubeId } = props
    const src = `https://i.ytimg.com/vi/${youtubeId}/default.jpg`

    return <img src={src} className={css.thumbnail} alt='' width={60} height={45} loading='lazy' />
}

type DurationProps = {
    value: number
}

function Duration(props: DurationProps): ReactElement {
    const { value } = props
    const formatted = formatDuration(value)
    return <span className={css.duration}>{formatted}</span>
}
