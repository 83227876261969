import { ReactElement, useEffect, useState } from 'react'

import { script } from './script'

import css from './styles.css'

type Player = YT.Player & {
    stateChange: Emitter<YT.OnStateChangeEvent>
}

type Video = {
    youtubeId: string
    title?: string
}

type Props = {
    width: number | string
    height: number | string
    videos: Video[]
    playing: boolean
    onPlay?: () => void
    onPause?: () => void
    index?: number
    onIndexChange?: (index: number) => void
}

function v(video: Video): string {
    return video.youtubeId
}

// A simple event emitter that allows unsubscribing from events (the YouTube iframe API does
// not allow this).
class Emitter<T> {
    listeners: ((evt: T) => void)[]
    constructor() {
        this.listeners = []
    }

    emit(evt: T) {
        for (const listener of this.listeners) {
            listener(evt)
        }
    }

    addEventListener(listener: (evt: T) => void) {
        this.listeners.push(listener)
    }

    removeEventListener(listener: (evt: T) => void) {
        this.listeners = this.listeners.filter((x) => x !== listener)
    }
}

export function YouTubePlayer(props: Props): ReactElement {
    const { videos = [], index = 0, width, height, playing = false, onPlay, onPause, onIndexChange } = props
    const [id, player] = usePlayer(!playing || videos.length === 0, videos, index)

    useEffect(
        function () {
            if (!player) {
                return () => undefined
            }

            function handle(evt: YT.OnStateChangeEvent): void {
                if (evt.data === YT.PlayerState.PAUSED) {
                    onPause?.()
                }
                if (evt.data === YT.PlayerState.PLAYING) {
                    onPlay?.()
                }
            }

            player.stateChange.addEventListener(handle)
            const t = setInterval(function () {
                const index = player.getPlaylistIndex()
                onIndexChange?.(videos.length === 1 ? 0 : Math.max(0, index))
            }, 250)

            return function () {
                clearInterval(t)
                player.stateChange.removeEventListener(handle)
            }
        },
        [player],
    )

    useEffect(
        function (): void {
            if (!player) {
                return
            }

            if (videos.length === 0) {
                player.pauseVideo()
                return
            }

            const vids = videos.map(v)
            const list = player.getPlaylist() as string[] | null

            if (videos.length === 1) {
                // @ts-expect-error
                const id = player.getVideoData().video_id as string
                const wrongVideo = id !== vids[0]
                const hasPlaylist = Boolean(list !== null)

                if (hasPlaylist || wrongVideo) {
                    player.loadVideoById(vids[0], 0)
                }
                return
            }

            const idx = player.getPlaylistIndex()
            const wrongList = !list || !equal(list, vids)
            const wrongIndex = index !== idx
            if (wrongIndex) {
                player.loadVideoById(vids[index])
            }
            if (wrongList || wrongIndex) {
                player.loadPlaylist(vids, index, 0)
            }
        },
        [player, index, videos],
    )

    return (
        <div className={css.player} style={{ width, height }}>
            <div id={id} style={{ width, height }} />
            {(videos.length === 0 || !player) && <Placeholder video={videos[index]} onPlay={onPlay} />}
        </div>
    )
}

function usePlayer(delay: boolean, videos: Video[], index: number): [string, Player | null] {
    const [id, setId] = useState('placeholder')
    const [player, setPlayer] = useState<Player | null>(null)

    useEffect(
        function (): void {
            if (delay) {
                return
            }

            if (player) {
                return
            }

            if (id === 'placeholder') {
                const id = `player-${Math.random()}`
                setId(id)
                return
            }

            async function load(): Promise<void> {
                const yt = await script()

                const p = await new Promise(function (resolve: (youtube: Player) => void): void {
                    const emitter = new Emitter<YT.OnStateChangeEvent>()

                    const player = new yt.Player(id, {
                        videoId: videos[index].youtubeId,
                        playerVars: {
                            rel: 0,
                            modestbranding: 1,
                            autohide: 1,
                            showinfo: 0,
                            enablejsapi: 1,
                        },
                        events: {
                            onReady(): void {
                                const p = player as Player
                                p.stateChange = emitter
                                resolve(p)
                            },
                            onStateChange(evt: YT.OnStateChangeEvent): void {
                                emitter.emit(evt)
                            },
                        },
                    })
                })

                setPlayer(p)
            }

            void load()
        },
        [delay, videos, index, player, id],
    )

    return [id, player]
}

type PlaceholderProps = {
    video: Video | null
    onPlay?: () => void
}

function Placeholder(props: PlaceholderProps): ReactElement | null {
    const { video, onPlay } = props

    return (
        <button
            className={css.placeholder}
            type='button'
            onClick={onPlay}
            disabled={!video}
            title={video?.title ? `Play ${video.title}` : 'Play'}
            aria-label='Play'
        >
            {video && <img src={`https://i.ytimg.com/vi/${video.youtubeId}/hqdefault.jpg`} alt='' />}
            <svg viewBox='0 0 68 48' className={css.youtube} aria-label='Play'>
                <path
                    className={css.background}
                    d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z'
                />
                <path className={css.foreground} d='M 45,24 27,14 27,34' />
            </svg>
        </button>
    )
}

function equal(a: string[], b: string[]): boolean {
    if (a.length !== b.length) {
        return false
    }

    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) {
            return false
        }
    }

    return true
}
